import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { useDispatch, useSelector } from "react-redux";
import { AccountDetail } from "../../../../../data/models/types/Account";
import {
  currentModalName,
  isModalOpen,
  setLoading,
  setOpenModal,
} from "../../../../../data/store/slices/utilSlice";
import {
  getAccountActivity,
  getPlan,
} from "../../../../../data/api/accountAPI";
import { createTableHeaders } from "../../../../../utils/helperFunctions";
import CButton from "../../../../../components/formComponents/CButton";
import ConfigModals from "../../actionsModals";
import CModal from "../../../../../components/modal/CModal";
import EditAccount from "../../editAccount";
import CTable from "../../../../../components/table/CTable";
import notification from "../../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../../data/store/thunk/utilThunk";
import ActivityHistory from "../../../../../components/formComponents/ActivityHistory";

interface IAccountDetails {
  account?: AccountDetail;
  getAccountById: () => {};
}

const AccountDetails = ({ account, getAccountById }: IAccountDetails) => {
  const lender: any = localStorage.getItem("lenderName");
  const lenderId: any = localStorage.getItem("lenderId");
  const isEditModalOpen: boolean = useSelector(isModalOpen);
  const modalName: string = useSelector(currentModalName);
  const [schedulePaymentHeaders, setSchedulePaymentHeaders] = useState<any>([]);
  const [schedulePayments, setSchedulePayments] = useState<any>([]);
  const [paymentPlansHeader, setPaymentPlansHeader] = useState<any>([]);
  const [installmentsHeader, setInstallmentsHeader] = useState<any>([]);
  const [accountActivity, setAccountActivity] = useState<any>([]);
  const [paymentPlans, setPaymentPlans] = useState<any>([]);
  const dispatch: any = useDispatch();
  const { state } = useLocation();
  useEffect(() => {
    getSchedulePayment();
    getPaymentPlans();
    getActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSchedulePayment = async () => {
    try {
      dispatch(setLoading(true));
      const res: any = await getPlan({
        lender,
        accountId: state.accountId,
        borrowerId: state.borrowerid,
        type: "scheduled-payments",
      });
      if (res) {
        setSchedulePayments(res?.scheduledPayments!);
        setSchedulePaymentHeaders(createTableHeaders(res?.headers!));
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const getPaymentPlans = async () => {
    try {
      dispatch(setLoading(true));
      const res: any = await getPlan({
        lender,
        accountId: state.accountId,
        borrowerId: state.borrowerid,
        type: "payment-plans",
      });

      if (res) {
        setPaymentPlansHeader(createTableHeaders(res?.paymentPlansHeaders));
        setInstallmentsHeader(createTableHeaders(res?.installmentsHeaders));
        setPaymentPlans(res?.paymentPlans);
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const getActivity = async () => {
    try {
      dispatch(setLoading(true));
      const res: any = await getAccountActivity({
        lender,
        accountId: state.accountId,
        borrowerId: state.borrowerid,
      });

      if (res) {
        const tempObj = res.accountTimelineItems.map((e: any) => ({
          date: new Date(e.timestamp).toDateString(),
          daysPassed: e.daysBeforeToday,
          activity: e.description,
        }));

        setAccountActivity(tempObj);
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const setEditModalOpen = (state: boolean) => {
    dispatch(setOpenModal({ modalName: "edit_account", openModel: state }));
  };

  return (
    <div>
      <div className="px-10 py-8">
        <div className="flex justify-between items-center">
          <h4 className="text-xl font-semibold leading-[31px]">
            Accounts Details
          </h4>
          <CButton
            type="outlined"
            text="Edit Account"
            icon={<EditIcon />}
            onClick={() => setEditModalOpen(true)}
          />
        </div>
        <div className="h-[0px] border border-gray-500 border-opacity-30 mt-3" />
        <div className="mt-6 justify-between items-end gap-12 flex flex-wrap mb-10">
          <div className="h-16 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-neutral-700 text-sm font-semibold leading-[18.62px]">
              Merchant Name
            </div>
            <div className="text-zinc-900 text-lg font-semibold leading-[29.88px]">
              {account?.merchantName || "-"}
            </div>
          </div>
          <div className="h-16 flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-neutral-700 text-sm font-semibold leading-[18.62px]">
              Loan Type
            </div>
            <div className="text-zinc-900 text-lg font-semibold leading-[29.88px]">
              {account?.loanType || "-"}
            </div>
          </div>
          <div className="h-16 flex-col justify-start items-start gap-3 inline-flex">
            <div className="text-neutral-700 text-sm font-semibold leading-[18.62px]">
              Delinquent Days
            </div>
            <div className="text-zinc-900 text-lg font-semibold leading-[29.88px]">
              {account?.delinquentDaysOnSubmission
                ? account?.delinquentDaysOnSubmission + " Days"
                : "-"}
            </div>
          </div>
          <div className="h-16 flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-neutral-700 text-sm font-semibold leading-[18.62px]">
              Account Submitted
            </div>
            <div className="text-zinc-900 text-lg font-semibold leading-[29.88px]">
              {account?.accountSubmitted || "-"}
            </div>
          </div>
          <div className="h-16 flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-neutral-700 text-sm font-semibold leading-[18.62px]">
              Loan Start Date
            </div>
            <div className="text-zinc-900 text-lg font-semibold leading-[29.88px]">
              {account?.startDate || "-"}
            </div>
          </div>
          <div className="h-16 flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="text-neutral-700 text-sm font-semibold leading-[18.62px]">
              Due Date
            </div>
            <div className="text-zinc-900 text-lg font-semibold leading-[29.88px]">
              {account?.dueDate || "-"}
            </div>
          </div>
        </div>
        <>
          <div className="flex justify-between items-center">
            <h4 className="text-xl font-semibold leading-[31px]">
              Account Configurations
            </h4>
          </div>
          <div className="h-[0px] border border-gray-500 border-opacity-30 mt-3" />
          <div className="justify-start items-start gap-6 inline-flex mt-6 mb-10">
            <div className="justify-start items-start gap-6 flex flex-wrap">
              <CButton
                type="outlined"
                text="Pay in Full"
                onClick={() =>
                  dispatch(
                    setOpenModal({
                      modalName: "pay_in_full",
                      openModel: true,
                    })
                  )
                }
                icon={<AccountBalanceWalletOutlinedIcon />}
              />
              <CButton
                type="outlined"
                text="Schedule Payment"
                onClick={() =>
                  dispatch(
                    setOpenModal({
                      modalName: "schedule_payment",
                      openModel: true,
                    })
                  )
                }
                icon={<EventOutlinedIcon />}
              />
              <CButton
                type="outlined"
                text="Payment Plan"
                onClick={() =>
                  dispatch(
                    setOpenModal({
                      modalName: "payment_plan",
                      openModel: true,
                    })
                  )
                }
                icon={<EventAvailableOutlinedIcon />}
              />
              {/* <CButton
              type="outlined"
              text="Offer Discount"
              icon={<SellOutlinedIcon />}
            />*/}
              <CButton
                type="outlined"
                text="Pause Collection"
                onClick={() =>
                  dispatch(
                    setOpenModal({
                      modalName: "pause_collection",
                      openModel: true,
                    })
                  )
                }
                icon={<PauseCircleOutlineOutlinedIcon />}
              />
              <CButton
                type="outlined"
                text="Withdraw Account"
                onClick={() =>
                  dispatch(
                    setOpenModal({
                      modalName: "withdraw_account",
                      openModel: true,
                    })
                  )
                }
                icon={<PersonRemoveOutlinedIcon />}
              />
              <CButton
                type="outlined"
                text="Change Status"
                onClick={() =>
                  dispatch(
                    setOpenModal({
                      modalName: "change_status",
                      openModel: true,
                    })
                  )
                }
                icon={<LoopOutlinedIcon />}
              />
            </div>
          </div>
        </>
        {schedulePayments?.length > 0 && (
          <div className="mb-10">
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-semibold leading-[31px]">
                Scheduled Payments
              </h4>
            </div>
            <div className="h-[0px] border border-gray-500 border-opacity-30 mt-3" />
            <div className="w-full justify-start items-start gap-6 inline-flex mt-6">
              <CTable
                headCells={schedulePaymentHeaders}
                rows={schedulePayments}
                minWidth={750}
                rowIdentifier="publicId"
              />
            </div>
          </div>
        )}
        {paymentPlans?.length > 0 && (
          <div>
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-semibold leading-[31px]">
                Installmet Plans
              </h4>
            </div>
            <div className="h-[0px] border border-gray-500 border-opacity-30 mt-3" />
            <div className="w-full justify-start items-start gap-6 inline-flex mt-6">
              <CTable
                headCells={paymentPlansHeader}
                subHeadCells={installmentsHeader}
                rows={paymentPlans}
                expandable={true}
                minWidth={750}
                rowIdentifier="publicId"
              />
            </div>
          </div>
        )}

        <div className="mt-8">
          <ActivityHistory activities={accountActivity} />
        </div>
      </div>

      <ConfigModals
        status={account?.statusValue!}
        amount={account?.balanceAmount}
        accountId={state.accountId}
        borrowerId={state.borrowerid}
        lender={lender}
        refreshAccount={() => {
          getAccountById();
          getActivity();
        }}
      />

      {modalName === "edit_account" && (
        <CModal
          title="Edit Account"
          body={
            <EditAccount
              account={account}
              accountId={state.accountId}
              borrowerId={state.borrowerid}
              lender={lender}
              refreshAccount={() => {
                getAccountById();
                getActivity();
              }}
            />
          }
          icon={<EditIcon />}
          open={isEditModalOpen}
          setOpen={setEditModalOpen}
          size="md"
          actionButton
          actionButtonText="Save"
          actionButtonForm={"update_account"}
          submit={true}
        />
      )}
    </div>
  );
};

export default AccountDetails;
