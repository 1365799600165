/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from "../../../../components/borrowerComponents/PageHeader";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import CButton from "../../../../components/formComponents/CButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CTable from "../../../../components/table/CTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBorrowers } from "../../../../data/api/borrowerAPI";
import { borrowersResponse } from "../../../../data/models/response/borrowersResponse";
import { borrower } from "../../../../data/models/types/borrower";
import { createTableHeaders } from "../../../../utils/helperFunctions";
import { useDispatch } from "react-redux";
import { setBorrowerId } from "../../../../data/store/slices/utilSlice";
import { setSkeletonWithDelay } from "../../../../data/store/thunk/utilThunk";
import notification from "../../../../utils/toast";

interface IAllBorrowers {}

const AllBorrowers = (props: IAllBorrowers) => {
  const lenderName: any = localStorage.getItem("lenderName");
  const [borrowers, setBorrowers] = useState<borrower[]>([]);
  const [borrowersCount, setBorrowersCount] = useState<number>(0);
  const [headers, setHeaders] = useState<any>([]);
  const [tableFilter, setTableFilter] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const tableFilters = [
    "All",
    "In Collection",
    "Payment Plan",
    "Scheduled Payment",
    "Settled",
    "Disputed",
  ];

  useEffect(() => {
    getAllBorrowers(10, 0);
  }, [tableFilter, searchString]);

  const getAllBorrowers = async (size: number, page: number) => {
    try {
      dispatch(setSkeletonWithDelay(true));
      const res: borrowersResponse | undefined = await getBorrowers({
        lender: lenderName,
        size: size,
        page: page,
        filter: tableFilter,
        searchString: searchString,
      });

      if (res) {
        setBorrowers(res?.borrowers!);
        setBorrowersCount(res?.total!);
        setHeaders(createTableHeaders(res?.headers!));
      }
      dispatch(setSkeletonWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const onFilterClick = (filterIndex: number) => {
    const filterProperty = tableFilters[filterIndex];
    switch (filterProperty) {
      case "All":
        setTableFilter("");
        break;
      case "In Collection":
        setTableFilter("in-collection");
        break;
      case "Payment Plan":
        setTableFilter("payment-plan");
        break;
      case "Scheduled Payment":
        setTableFilter("scheduled");
        break;
      case "Settled":
        setTableFilter("resolved");
        break;
      case "Disputed":
        setTableFilter("disputed");
        break;

      default:
        break;
    }
  };

  const onRowClick = (rowId?: number) => {
    dispatch(setBorrowerId(rowId));
    navigate("/borrower", { state: { borrowerid: rowId } });
  };

  return (
    <>
      <PageHeader
        title="Borrowers"
        desc="Get insights into borrower accounts and statuses with just a click. Simplifying your borrower management experience."
      />
      <div className="px-10 py-8">
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex items-end">
            <InputField
              className="grow lg:w-[560px]"
              placeholder="Search by name, email, phone etc..."
              search={true}
              onSearchSubmit={setSearchString}
              name="search"
            />
            <CButton
              className="ml-3"
              text=""
              type="outlined"
              varient="icon-button"
              icon={<FilterListIcon />}
            />
          </div>
          {/* <CButton
            className=" w-fit tracking-[0.46px] font-semibold text-sm"
            text="Upload Borrowers"
            type="contained"
            icon={<PersonAddIcon />}
          /> */}
        </div>
        <div className="mt-8">
          <CTable
            headCells={headers}
            rows={borrowers}
            tabs={true}
            minWidth={750}
            tableFilters={tableFilters}
            onRowClick={onRowClick}
            refreshDataAPI={getAllBorrowers}
            totalRows={borrowersCount}
            filterAction={onFilterClick}
            noOfCol={5}
            rowIdentifier="publicId"
          />
        </div>
      </div>
    </>
  );
};

export default AllBorrowers;
