import { useEffect, useState } from "react";
import {
  getBarChart,
  getLineChart,
  getPieChart,
  getTable,
  getTiles,
} from "../../../data/api/dashboardAPI";
import Tile from "../../../components/dashboardComponents/Tile";
import { dashboardResponse } from "../../../data/models/response/dashboardResponse";
import { convertAmount } from "../../../utils/helperFunctions";
import PieChart from "../../../components/dashboardComponents/PieChart";
import { widget } from "../../../data/models/types/widget";
import BarChart from "../../../components/dashboardComponents/BarChart";
import LineChart from "../../../components/dashboardComponents/LineChart";
import TableChart from "../../../components/dashboardComponents/TableChart";
import { useDispatch } from "react-redux";
import notification from "../../../utils/toast";
import { setSkeletonWithDelay } from "../../../data/store/thunk/utilThunk";
import { Card, CircularProgress, Skeleton } from "@mui/material";

interface IDashboard {}

const Dashboard = (props: IDashboard) => {
  const [tiles, setTiles] = useState<widget[] | undefined>([]);
  const [pieCharts, setPieCharts] = useState<widget[] | undefined>([]);
  const [barCharts, setBarCharts] = useState<widget[] | undefined>([]);
  const [lineCharts, setLineCharts] = useState<widget[] | undefined>([]);
  const [tableData, setTableData] = useState<widget[] | undefined>([]);
  const dispatch: any = useDispatch();

  useEffect(() => {
    getAllTiles();
    getAllPieChart();
    getAllBarChart();
    getAllLineChart();
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTiles = async () => {
    try {
      dispatch(setSkeletonWithDelay(true));
      const res: dashboardResponse | undefined = await getTiles();
      if (res) setTiles(res?.widgets);
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const getAllPieChart = async () => {
    try {
      const res: dashboardResponse | undefined = await getPieChart();
      if (res) setPieCharts(res?.widgets);
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const getAllBarChart = async () => {
    try {
      const res: dashboardResponse | undefined = await getBarChart();
      if (res) setBarCharts(res?.widgets);
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const getAllLineChart = async () => {
    try {
      const res: dashboardResponse | undefined = await getLineChart();
      if (res) setLineCharts(res?.widgets);
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const getTableData = async () => {
    try {
      const res: dashboardResponse | undefined = await getTable();
      if (res) {
        setTableData(res?.widgets);
        dispatch(setSkeletonWithDelay(false));
      }
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  return (
    <>
      {/* <div className="w-full py-4 bg-[#F0F4F8]">
        <div className="py-5 px-8">
          <img className="w-36" src={""} alt="ClearGrid_logo" />
        </div>
      </div> */}
      <div className="container mx-auto px-2 sm:px-10 py-5">
        <div className="w-full my-6">
          <h4 className="text-xl font-semibold">All Stats</h4>
        </div>
        <div className="flex flex-wrap">
          {tiles &&
            tiles!.length < 1 &&
            Array.from({ length: 10 }).map((val, idx) => (
              <>
                <div
                  key={idx}
                  className="min-[1024px]:w-1/3 min-[1280px]:w-1/4 min-[1536px]:w-1/5"
                >
                  <Card
                    variant="outlined"
                    className="grow h-[10rem] p-4 shadow-md rounded-md mb-4 sm:mr-4 border-b-2 border-b-primary"
                  >
                    <Skeleton animation="wave" height={30} />
                    <Skeleton animation="wave" height={20} />
                    <Skeleton animation="wave" height={50} className="mt-6" />
                  </Card>
                </div>
              </>
            ))}
          {tiles?.map(({ id, title, subHeading, unit, value }) => (
            <div
              key={id}
              className="min-[1024px]:w-1/3 min-[1280px]:w-1/4 min-[1536px]:w-1/5"
            >
              <Tile
                key={id}
                title={title}
                subTitle={subHeading}
                desc={
                  unit === null
                    ? convertAmount(value)
                    : `${convertAmount(value)} ${unit}`
                }
              />
            </div>
          ))}
        </div>

        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-2/5">
            {pieCharts &&
              pieCharts!.length < 1 &&
              Array.from({ length: 1 }).map((val, idx) => (
                <Card
                  variant="outlined"
                  className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
                >
                  <div className="flex justify-center items-center h-full">
                    <CircularProgress size={65} color="primary" />
                  </div>
                </Card>
              ))}
            {pieCharts?.map(({ id, title, data }) => (
              <PieChart key={id} title={title} data={data} />
            ))}
          </div>

          <div className="w-full lg:w-1/2 xl:w-3/5">
            {barCharts &&
              barCharts!.length < 1 &&
              Array.from({ length: 1 }).map((val, idx) => (
                <Card
                  variant="outlined"
                  className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
                >
                  <div className="flex justify-center items-center h-full">
                    <CircularProgress size={65} color="primary" />
                  </div>
                </Card>
              ))}
            {barCharts?.map(({ id, title, data, labelXAxis, labelYAxis }) => (
              <BarChart
                key={id}
                title={title}
                data={data}
                labelXAxis={labelXAxis}
                labelYAxis={labelYAxis}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-3/5">
            {lineCharts &&
              lineCharts!.length < 1 &&
              Array.from({ length: 1 }).map((val, idx) => (
                <Card
                  variant="outlined"
                  className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
                >
                  <div className="flex justify-center items-center h-full">
                    <CircularProgress size={65} color="primary" />
                  </div>
                </Card>
              ))}
            {lineCharts?.map(({ id, title, data, labelXAxis, labelYAxis }) => (
              <LineChart
                key={id}
                title={title}
                data={data}
                labelXAxis={labelXAxis}
                labelYAxis={labelYAxis}
              />
            ))}
          </div>

          <div className="w-full lg:w-1/2 xl:w-2/5">
            {tableData &&
              tableData!.length < 1 &&
              Array.from({ length: 1 }).map((val, idx) => (
                <Card
                  variant="outlined"
                  className="grow shadow-md rounded-md mb-4 sm:mr-4 p-4 h-[27.2rem]"
                >
                  <div className="flex justify-center items-center h-full">
                    <CircularProgress size={65} color="primary" />
                  </div>
                </Card>
              ))}
            {tableData?.map(({ id, title, data }) => (
              <TableChart key={id} title={title} data={data} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
