import { useEffect, useState } from "react";
import CButton from "../../../../../components/formComponents/CButton";
import InputField from "../../../../../components/formComponents/fieldComponents/InputField";
import CTable from "../../../../../components/table/CTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getDispiutesByAccountId } from "../../../../../data/api/disputeAPI";
import { disputesResponse } from "../../../../../data/models/response/disputeResponse";
import { dispute } from "../../../../../data/models/types/dispute";
import { createTableHeaders } from "../../../../../utils/helperFunctions";
import CModal from "../../../../../components/modal/CModal";
import { useDispatch, useSelector } from "react-redux";
import {
  currentModalName,
  isModalOpen,
  setOpenModal,
  setSkeleton,
} from "../../../../../data/store/slices/utilSlice";
import DisputeDetails from "../../../disputes/disputeDetails";
import { AccountDetail } from "../../../../../data/models/types/Account";
import notification from "../../../../../utils/toast";
import { setSkeletonWithDelay } from "../../../../../data/store/thunk/utilThunk";

interface IAccountDisputes {
  account?: AccountDetail;
}

const tableFilters = ["All", "Open", "Resolved", "Rejected"];

const AccountDisputes = ({ account }: IAccountDisputes) => {
  const lenderName: any = localStorage.getItem("lenderName");
  const [tableFilter, setTableFilter] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [disputes, setDisputes] = useState<dispute[]>([]);
  const [disputesCount, setDisputesCount] = useState<number>(0);
  const [disputeId, setDisputeId] = useState<number>(0);
  const [headers, setHeaders] = useState<any>([]);
  const isOpen: boolean = useSelector(isModalOpen);
  const modalName: string = useSelector(currentModalName);
  const dispatch: any = useDispatch();

  const setOpen = (state: boolean) => {
    dispatch(
      setOpenModal({ modalName: "viewDisputeDetail", openModel: state })
    );
  };

  useEffect(() => {
    getAccountDisputes(10, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, searchString]);

  const getAccountDisputes = async (size: number, page: number) => {
    try {
      dispatch(setSkeleton(true));
      console.log(account);
      const res: disputesResponse | undefined = await getDispiutesByAccountId({
        lender: lenderName,
        size: size,
        page: page,
        filter: tableFilter,
        accountId: account?.accountPublicId,
        searchString: searchString,
      });

      const actionButtons = (rowId: number) => (
        <>
          <CButton
            text="View"
            onClick={() => {
              setDisputeId(rowId);
              setOpen(true);
            }}
            type="outlined"
            className="h-8"
          />
        </>
      );

      if (res) {
        setDisputes(res?.disputes!);
        setDisputesCount(res?.total!);
        setHeaders(
          createTableHeaders(res?.headers!, { action: true, actionButtons })
        );
      }
      dispatch(setSkeletonWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const onFilterClick = (filterIndex: number) => {
    const filterProperty = tableFilters[filterIndex];
    switch (filterProperty) {
      case "All":
        setTableFilter("");
        break;
      case "Open":
        setTableFilter("open");
        break;
      case "Resolved":
        setTableFilter("resolved");
        break;
      case "Rejected":
        setTableFilter("rejected");
        break;

      default:
        break;
    }
  };

  const onRowClick = (rowId?: number) => {};

  return (
    <>
      <div className="px-10 py-8">
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex items-end">
            <InputField
              className="grow lg:w-[560px]"
              placeholder="Search by dispute id, borrower id, account id etc..."
              search={true}
              onSearchSubmit={setSearchString}
              name="search"
            />

            <CButton
              className="ml-3"
              text=""
              type="outlined"
              varient="icon-button"
              icon={<FilterListIcon />}
            />
          </div>
        </div>

        <div className="mt-8">
          <CTable
            headCells={headers}
            rows={disputes}
            tabs={true}
            minWidth={750}
            noOfCol={7}
            tableFilters={tableFilters}
            onRowClick={onRowClick}
            refreshDataAPI={getAccountDisputes}
            totalRows={disputesCount}
            filterAction={onFilterClick}
          />
        </div>
      </div>

      {modalName === "viewDisputeDetail" && isOpen && (
        <CModal
          title={`Dispute # ${disputeId}`}
          body={
            <DisputeDetails id={disputeId} refreshApi={getAccountDisputes} />
          }
          open={isOpen}
          setOpen={setOpen}
          size="lg"
          footer={false}
        />
      )}
    </>
  );
};

export default AccountDisputes;
