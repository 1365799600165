import CButton from "../../components/formComponents/CButton";
import InputField from "../../components/formComponents/fieldComponents/InputField";
import loginScreen from "../../data/assets/images/loginScreen.svg";
import Paper from "@mui/material/Paper";
import clearGridLogo from "../../data/assets/logos/clearGridLogo.svg";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Form, Formik } from "formik";
import { loginSchema } from "../../data/validations/ValidationScemas";
import { login } from "../../data/api/authAPI";
import { loginResponse } from "../../data/models/response/loginResponse";
import { useDispatch } from "react-redux";
import { logOut, setCredentials } from "../../data/store/slices/authSlice";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import notification from "../../utils/toast";

export interface ILoginProps {}
interface IformValues {
  email: string;
  password: string;
}

const Login = (props: ILoginProps) => {
  const { lenderName } = useParams();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(logOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen flex">
      <div className="grid flex-1 content-between bg-[#FFFFFF]">
        <div className="flex flex-col items-center">
          <img
            className="mb-16 mt-44 w-72"
            src={clearGridLogo}
            alt="ClearGrid_logo"
          />
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={async (values: IformValues) => {
              try {
                setLoading(true);
                const data: loginResponse | any = await login(
                  values,
                  lenderName
                );
                if (
                  data.code !== 401 &&
                  lenderName !== null &&
                  lenderName !== undefined &&
                  lenderName !== "null"
                ) {
                  localStorage.setItem("lenderName", lenderName);
                  localStorage.setItem("token", data.token);
                  localStorage.setItem("refreshToken", data.refreshToken);
                  localStorage.setItem("lenderId", data.lenderId);

                  dispatch(setCredentials(data));
                  navigate("/", { replace: true });
                } else {
                  setLoading(false);
                  if (lenderName === "null")
                    notification("Lender subdomain not found.", "error");
                  else notification("Bad credentials", "error");
                }
              } catch (error) {
                console.log(error);
                setLoading(false);
              }
            }}
          >
            {({ errors }) => (
              <Paper
                className="py-10 px-14 h-[500px] w-[525px] rounded-lg"
                elevation={3}
              >
                <Form>
                  <div>
                    <h1 className="mb-10 tracking-wider text-2xl font-bold text-[#32383E]">
                      Login to your Account
                    </h1>
                    <InputField
                      className="mb-5"
                      placeholder="Enter email"
                      label="Email"
                      name="email"
                      errorText={errors.email}
                    />
                    <InputField
                      placeholder="Enter password"
                      label="Password"
                      name="password"
                      password={true}
                      errorText={errors.password}
                    />
                    {/* <p className="w-full text-end font-bold text-sm text-primary my-2">
                      Forgot your password?
                    </p> */}
                  </div>
                  <div className="mt-16">
                    <CButton
                      className="w-full"
                      text={`Continue`}
                      type="contained"
                      loading={loading}
                      submit={true}
                    />
                  </div>
                </Form>
              </Paper>
            )}
          </Formik>
          {/* <CButton
            className="mt-16"
            text="Contact Support"
            type="outlined"
            varient="contact"
            icon={[<CommentOutlinedIcon />, <CommentIcon />]}
          /> */}
        </div>
        <div className="flex justify-center">
          <div className="flex justify-center items-center w-8/12 p-2">
            <p className="flex">
              <CopyrightIcon />
              &nbsp;ClearGrid
            </p>
            {/* <p className="font-bold text-primary">Privacy & Terms</p> */}
            {/* <SelectField
              className="w-32"
              options={[
                { label: "English", value: "en" },
                { label: "Arabic", value: "ar" },
              ]}
            /> */}
          </div>
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-col lg:justify-between lg:flex-1 lg:bg-primary">
        <div className="mx-20 mt-20 text-[#FBFCFE]">
          <h1 className="text-4xl font-bold leading-10 w-[515px] xl:w-[650px] xl:text-6xl">
            Debt Resolution Powered by Emerging Technologies
          </h1>
          <p className="text-base font-medium leading-6 w-[610px] mt-6  xl:text-lg xl:w-[720px]">
            Discover the future of debt resolution where emerging technologies
            meet advanced analytics to boost recovery rates and strengthen
            customer bonds. ClearGrid equips lenders with innovative tools and
            actionable insights, ensuring compliance and scalable solutions for
            any collection volume.
          </p>
        </div>
        <div className="overflow-hidden mt-10 ml-10">
          <img
            className="object-fill w-full"
            src={loginScreen}
            alt="login-screen-pic"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
